const REACT_APP_URL = 'http://192.168.99.200:6879/api';
const CERTIFICATE = 'Certificate';
const DRAFT = 'Draft';
const AUTH = 'Authentication';
const USER_INFO = 'UserInfo';
const PKI_API = 'PkiApi';
const SVS_API = 'SvsApi';
const FRONT_CONFIG = 'FrontConfig';

export const API = {
    privateRoomInfo: `${REACT_APP_URL}/`,
    EnsureSession: `${REACT_APP_URL}/${AUTH}/ensure-session`,
    getEsiaLoginUrl: `${REACT_APP_URL}/${AUTH}/get-esia-login-url`,
    loginByCertificate: `${REACT_APP_URL}/${AUTH}/login-by-certificate`,
    loginByEsia: `${REACT_APP_URL}/${AUTH}/login-by-esia`,
    logout: `${REACT_APP_URL}/${AUTH}/logout`,
    logoutEsia: `${REACT_APP_URL}/${AUTH}/esia-logout-url`,
    refreshToken: `${REACT_APP_URL}/${AUTH}/refresh-token`,
    getCertificatesList: `${REACT_APP_URL}/${CERTIFICATE}`,
    getCertificatePKIDetails: `${REACT_APP_URL}/${CERTIFICATE}/get-pki-details`,
    getDraft: `${REACT_APP_URL}/${DRAFT}/get-draft`,
    removeDraft: `${REACT_APP_URL}/${DRAFT}/remove-draft`,
    createCertificateRequest: `${REACT_APP_URL}/${CERTIFICATE}/create-request`,
    createDraftRequest: `${REACT_APP_URL}/${DRAFT}/ensure-update-draft`,
    revokeCertificateRequest: `${REACT_APP_URL}/${CERTIFICATE}/revoke-pki-certificate`,
    applyCertificateRequest: `${REACT_APP_URL}/${CERTIFICATE}/send-request`,
    sendToArchive: `${REACT_APP_URL}/${CERTIFICATE}/archive-pki-certificate`,
    userInfo: `${REACT_APP_URL}/${USER_INFO}`,
    countries: `${REACT_APP_URL}/${USER_INFO}/countries`,
    template: `${REACT_APP_URL}/Template`,
    pkiApi: `${REACT_APP_URL}/${PKI_API}`,
    printedTemplate: `${REACT_APP_URL}/PrintTemplate`,
    namePolicy: `${REACT_APP_URL}/NamePolicy`,
    userName: `${REACT_APP_URL}/${USER_INFO}/user-name`,
    getSvsPolicy: `${REACT_APP_URL}/${SVS_API}/policy`,
    checkVerifyCertificate: `${REACT_APP_URL}/${SVS_API}/verify-certificate`,
    checkSignatureDocuments: `${REACT_APP_URL}/${SVS_API}/verify-signatures`,
    getFrontConfig: `${REACT_APP_URL}/${FRONT_CONFIG}/get-links`,
};
